export default {
	DASHBOARD: {
		name: 'dashboard',
		title: 'Dashboard',
		route: '/',
		location: '/',
	},
	USERS: {
		name: 'users',
		title: 'Users',
		route: '/users',
		location: '/users',
	},
	USERS_VOTES: {
		name: 'users_votes',
		title: 'User Votes',
		route: '/users/votes',
		location: '/users/votes',
	},
	REFERRALS: {
		name: 'referrals',
		title: 'Referrals',
		route: '/referrals',
		location: '/referrals',
	},
	AIRTABLE: {
		name: 'airtable',
		title: 'Airtable',
		route: '/airtable',
		location: '/airtable',
	},
	NAME_COLLISIONS: {
		name: 'name_collisions',
		title: 'Name Collisions',
		route: '/users/name-collisions',
		location: '/users/name-collisions',
	},
	LOGIN: {
		name: 'login',
		title: 'Login',
		route: '/login',
		location: '/login',
	},
	LOGOUT: {
		name: 'logout',
		title: 'Logout',
		route: '/logout',
		location: '/logout',
	},
}
