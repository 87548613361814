import { GET_LIST_REFERRALS_SUCCESS } from '@Store/Referrals/actionTypes'

const INIT_STATE = {
	list: []
}

const Referrals = (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_LIST_REFERRALS_SUCCESS:
			return {
				...state,
				list: action.payload
			}
		default:
			return state
	}
}

export default Referrals
