import React, { useState } from 'react'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import * as actions from '@Store/SyncActions/usersActions'
import { getListNameCollisions } from '@Store/Users/actions'
import { useDispatch } from 'react-redux'
import * as notifications from '@Notifications/Notifications'

const Buttons = props => {

	const { row } = props

	const dispatch = useDispatch()

	const [dropdownOpen, setDropdownOpen] = useState(false)

	return (
		<ButtonDropdown
			size="sm"
			isOpen={dropdownOpen}
			toggle={() => setDropdownOpen(!dropdownOpen)}
		>
			<DropdownToggle caret color="primary" outline>
				Actions <i className="mdi mdi-chevron-down"></i>
			</DropdownToggle>
			<DropdownMenu>
				{/* Pagerank Graph */}
				<DropdownItem className="d-flex align-items-center" onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					let r = prompt('Type \'ignore\' to confirm the action')
					if(r !== 'ignore'){
						return
					}
					actions.setAnalyzedName(row.id)
						.then(r => {
							dispatch(getListNameCollisions())
							notifications.success()
						})
				}}>
					Ignore
				</DropdownItem>
				{/* Invites Graph */}
				<DropdownItem className="d-flex align-items-center" onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					let r = prompt('Type \'apply update\' to confirm the action')
					if(r !== 'apply update'){
						return
					}
					actions.applyChangeToName(row.id)
						.then(r => {
							dispatch(getListNameCollisions())
							notifications.success()
						})
				}}>
					Update
				</DropdownItem>
			</DropdownMenu>
		</ButtonDropdown>
	)
}

export default Buttons
