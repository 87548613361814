import { CREATE_NEW_REFERRAL, GET_LIST_REFERRALS, GET_LIST_REFERRALS_SUCCESS } from '@Store/Referrals/actionTypes'

export const getListReferrals = () => ({
	type: GET_LIST_REFERRALS,
})

export const getListReferralsSuccess = (data) => ({
	type: GET_LIST_REFERRALS_SUCCESS,
	payload: data,
})

export const createNewReferral = (nameToDisplay, networkPid, facultyPid) => ({
	type: CREATE_NEW_REFERRAL,
	payload: { nameToDisplay, networkPid, facultyPid },
})
