import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateAirtableMap } from '@Store/Airtable/actions'
import Select from 'react-select'
import * as notifications from '@Notifications/Notifications'

const UpdateFaculty = props => {

	const { facultyId, facultyName } = props

	const dispatch = useDispatch()

	const listFacultiesData = useSelector(state => state.Airtable.listFaculties)
	const [listFaculties, setListFaculties] = useState(listFacultiesData)
	useEffect(() => { setListFaculties(listFacultiesData)}, [listFacultiesData])

	const updateAirtableMapData = (airtableName) => {
		if(confirm(`Do you want to update the value for ${facultyName} to ${airtableName}`)){
			dispatch(updateAirtableMap(facultyId, airtableName))
			notifications.success()
		}
	}

	return (
		<Select
			options={listFaculties.map(faculty => ({ value: faculty, label: faculty }))}
			onChange={airtableName => updateAirtableMapData(airtableName.value)}
			placeholder="Find user in database"
			classNamePrefix="select2-selection"
		/>
	)
}

export default UpdateFaculty
