import React from 'react'
import axios from 'axios'
import { getHeaders } from '@Store/helpers'

export const autocompleteFaculty = (input, callback, network) => {
	if (!network) return callback([])
	axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/public/faculties?fields=id,name`, {
		...getHeaders(),
		params: {
			network: network.value,
			term: input
		}
	}).then(res => {
		callback(res.data.map(faculty => ({ value: faculty.id, label: faculty.name })))
	})
}
