import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
// Import Routes all
import { authProtectedRoutes, publicRoutes } from '@Routes/index'
// Import all middleware
import Authmiddleware from '@Routes/authmiddleware'
// layouts Format
import Page404 from '@Pages/Utility/page-404'

// Import scss
import '@Assets/scss/theme.scss'
import { useDispatch } from 'react-redux'
import { getListNetworks } from '@Store/Network/actions'

const App = props => {

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getListNetworks())
	}, [])

	return (
		<React.Fragment>
			<BrowserRouter>
				<Switch>
					{publicRoutes.map((route, k) => (
						<Authmiddleware
							path={route.path}
							layout={route.layout}
							component={route.component}
							key={k}
							isAuthProtected={false}
							redirectIfAuthorized={route.redirectIfAuthorized}
							exact={route.exact}
						/>
					))}

					{authProtectedRoutes.map((route, k) => (
						<Authmiddleware
							path={route.path}
							layout={route.layout}
							component={route.component}
							key={k}
							isAuthProtected={true}
							exact={route.exact}
						/>
					))}
					<Route component={Page404}/>
				</Switch>
			</BrowserRouter>
		</React.Fragment>
	)
}

App.propTypes = {
	layout: PropTypes.any,
}

export default App
