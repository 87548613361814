import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

class NonAuthLayout extends Component {
	constructor (props) {
		super(props)
		this.state = {}
	}

	render () {
		return <React.Fragment>{this.props.children}</React.Fragment>
	}
}

NonAuthLayout.propTypes = {
	children: PropTypes.object,
	location: PropTypes.object
}

export default withRouter(NonAuthLayout)
