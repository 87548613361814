import React from 'react'

export const tableColumns = [
	{
		dataField: 'code',
		text: 'Code',
		alignHeader: 'center',
		sort: true
	},
	{
		dataField: 'name',
		text: 'Name to display',
		sort: true
	},
	{
		dataField: 'networkName',
		text: 'Network',
		sort: true
	},
	{
		dataField: 'facultyName',
		text: 'Faculty',
		sort: true
	},
	{
		dataField: 'link',
		text: 'Link',
		sort: true,
		formatter: (cell, row) => {
			return `${process.env.REACT_APP_WIRAKI_WEBSITE}/invite/${row.code}`
		}
	}
]

export const tableOptions = tableLength => {
	return ({
		sizePerPage: 25,
		totalSize: tableLength, // replace later with size(customers),
		custom: true,
	})
}

export const tableDefaultSorted = [{
	dataField: 'id',
	order: 'asc'
}]
