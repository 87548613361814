import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getHeaders, handleError } from '@Store/helpers'
import { getListNetworksSuccess } from '@Store/Network/actions'
import { GET_LIST_NETWORKS } from '@Store/Network/actionTypes'


function * networkLoadList () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/public/networks?fields=id,name,logo`, getHeaders()))
		yield put(getListNetworksSuccess(data))
	} catch (err) { handleError(err) }
}

function * NetworkSaga () {
	yield all([
		yield takeEvery(GET_LIST_NETWORKS, networkLoadList),
	])
}

export default NetworkSaga
