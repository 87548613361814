import { GET_LIST_NAME_COLLISIONS, GET_LIST_NAME_COLLISIONS_SUCCESS, SAVE_EDIT_USER, SAVED_EDIT_USER, TOGGLE_MODAL_EDIT_USER, USER_LIST_LOAD, USER_LIST_SAVE } from '@Store/Users/actionTypes'

export const userListLoad = ({ networkId, facultyId, term }) => ({
	type: USER_LIST_LOAD,
	payload: {
		networkId,
		facultyId,
		term
	}
})
export const userListSave = users => ({
	type: USER_LIST_SAVE,
	payload: users
})

export const toggleModalEditUser = (open = true, userData = null) => ({
	type: TOGGLE_MODAL_EDIT_USER,
	payload: {
		open,
		data: userData
	}
})
export const saveEditUser = (userId, data) => ({
	type: SAVE_EDIT_USER,
	payload: {
		userId,
		data
	}
})
export const savedEditUser = () => ({
	type: SAVED_EDIT_USER,
	payload: null
})
export const getListNameCollisions = () => ({
	type: GET_LIST_NAME_COLLISIONS,
	payload: null
})
export const saveListNameCollisions = list => ({
	type: GET_LIST_NAME_COLLISIONS_SUCCESS,
	payload: list
})
