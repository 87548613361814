import { put } from 'redux-saga/effects'
import { errorAxiosCall, errorAxiosCall400, errorAxiosCall500, genericError } from '@Store/system/actions'
import { isEmpty, isPlainObject, keyBy, mergeWith } from 'lodash'

function getLanguage() {
	return {
		lang: navigator.language.substr(0, 2),
		langFull: navigator.language,
	}
}

function getAuth () {
	return JSON.parse(window.localStorage.getItem('apikey')) || null
}

function getHeaders () {
	return {
		headers: {
			'Content-type': 'application/json',
			'apikey': getAuth()
		}
	}
}

function handleError (error) {
	if (error.response && error.response.status) {
		if (error.response.status === 401) {
			localStorage.removeItem('authUser')
			window.location.href = process.env.REACT_APP_USER_AREA_URL
		} else if (error.response.status >= 400 && error.response.status < 500) {
			put(errorAxiosCall400(error.message))
		} else if (error.response.status >= 500 && error.response.status < 600) {
			put(errorAxiosCall500(error.message))
		} else {
			put(errorAxiosCall(error.message))
		}
	} else {
		put(genericError(error.message))
	}
}

function urlBase64ToUint8Array (base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4)
	// eslint-disable-next-line
	const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

	const rawData = window.atob(base64)
	const outputArray = new Uint8Array(rawData.length)

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i)
	}
	return outputArray
}

// https://stackoverflow.com/a/49437903/1828637
// mergeWith customizer.
// by default mergeWith keeps refs to everything,
// this customizer makes it so that ref is only kept if unchanged
// and a shallow copy is made if changed. this shallow copy continues deeply.
// supports arrays of collections (by id).
function keepUnchangedRefsOnly (objValue, srcValue) {
	if (objValue === undefined) { // do i need this?
		return srcValue
	} else if (srcValue === undefined) { // do i need this?
		return objValue
	} else if (isPlainObject(objValue)) {
		return mergeWith({}, objValue, srcValue, keepUnchangedRefsOnly)
	} else if (Array.isArray(objValue)) {
		if (isEmpty(objValue) && !isEmpty(srcValue)) return [...srcValue]
		else if (!isEmpty(objValue) && isEmpty(srcValue)) return objValue
		else if (isEmpty(objValue) && isEmpty(srcValue)) return objValue // both empty
		else {
			// if array is array of objects, then assume each object has id, and merge based on id
			// so create new array, based objValue. id should match in each spot

			if (isPlainObject(objValue[0]) && objValue[0].hasOwnProperty('id')) {
				const srcCollection = keyBy(srcValue, 'id')

				const aligned = objValue.map(el => {
					const { id } = el
					if (srcCollection.hasOwnProperty(id)) {
						const srcEl = srcCollection[id]
						delete srcCollection[id]
						return mergeWith({}, el, srcEl, keepUnchangedRefsOnly)
					} else {
						return el
					}
				})

				aligned.push(...Object.values(srcCollection))

				return aligned
			} else {
				return [...objValue, ...srcValue]
			}
		}
	}
}

function updateState (state, newData) {
	return mergeWith({}, state, newData, keepUnchangedRefsOnly)
}

/* Deep check if two objects have same keys */
const deepSameKeys = (o1, o2) => {
	// Both nulls = yes
	if (o1 === null && o2 === null) {
		return true
	}
	// Get the keys of each object
	const o1keys = o1 === null ? [] : Object.keys(o1)
	const o2keys = o2 === null ? [] : Object.keys(o2)
	if (o1keys.length !== o2keys.length) {
		// Different number of own properties = not the same
		return false
	}

	// At this point, one of two things is true:
	// A) `o1` and `o2` are both `!null`, or
	// B) One of them is `null` and the other has own "own" properties
	// The logic below relies on the fact we only try to use `o1` or
	// `o2` if there's at least one entry in `o1keys`, which we won't
	// given the guarantee above.

	// Handy utility function
	const hasOwn = Object.prototype.hasOwnProperty

	// Check that the keys match and recurse into nested objects as necessary
	return o1keys.every(key => {
		if (!hasOwn.call(o2, key)) {
			// Different keys
			return false
		}
		// Get the values and their types
		const v1 = o1[key]
		const v2 = o2[key]
		const t1 = typeof v1
		const t2 = typeof v2

		if (t1 === 'object') {
			if (t2 === 'object' && !deepSameKeys(v1, v2)) {
				return false
			}
		}
		if (t2 === 'object') {
			if (t1 === 'object' && !deepSameKeys(v1, v2)) {
				return false
			}
		}
		return true
	})
}

/* Update o1 values from o2 without addding new keys */
const updateObjectValuesWithoutAdd = (o1, o2) => {
	/* If one is null, return {} */
	if (!o1 || !o2) return {}

	for (const key of Object.keys(o1)) {
		/* If o2 has not "k" as key */
		if (typeof o2[key] === 'undefined') continue
		/* If o1[key] is an array and o2[key] exists, override */
		if (Array.isArray(o1[key])) {
			o1[key] = o2[key]
			continue
		}
		/* If o1[key] is an object, go recursive */
		if (typeof o1[key] === 'object' && typeof o2[key] === 'object') {
			o1[key] = updateObjectValuesWithoutAdd(o1[key], o2[key])
		} else {
			o1[key] = o2[key]
		}
	}
	return o1
}

export {
	getAuth,
	getHeaders,
	handleError,
	urlBase64ToUint8Array,
	keepUnchangedRefsOnly,
	updateState,
	deepSameKeys,
	updateObjectValuesWithoutAdd,
	getLanguage,
}
