import React from 'react'
import axios from 'axios'
import { getHeaders } from '@Store/helpers'

export const autocompleteNetwork = (input, callback) => {
	axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/public/networks?fields=id,name`, {
		...getHeaders(),
		params: {
			term: input
		}
	}).then(res => {
		callback(res.data.map(network => ({ value: network.id, label: network.name })))
	})
}
