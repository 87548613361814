import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import TableResponsive from '@Molecules/TableResponsive'
import { tableColumns, tableDefaultSorted, tableOptions } from '@Pages/Referrals/referralTableConfig'
import { createNewReferral, getListReferrals } from '@Store/Referrals/actions'
import { useDispatch, useSelector } from 'react-redux'
import { autocompleteNetwork } from '@Store/SyncActions/networkActions'
import AsyncSelect from 'react-select/async'
import { autocompleteFaculty } from '@Store/SyncActions/facultyActions'

const Referrals = props => {

	const dispatch = useDispatch()

	const [overlay, setOverlay] = useState(false)
	const listReferralsData = useSelector(state => state.Referrals.list)
	const [listReferrals, setListReferrals] = useState(listReferralsData)

	const [newName, setNewName] = useState('')
	const [newNetwork, setNewNetwork] = useState()
	const [newFaculty, setNewFaculty] = useState()

	useEffect(() => {
		setOverlay(true)
		setListReferrals(listReferralsData)
	}, [listReferralsData])

	useEffect(() => {
		setOverlay(true)
		dispatch(getListReferrals())
	}, [])

	useEffect(() => {
		setOverlay(false)
	}, [listReferrals])

	return (
		<Card>
			<CardBody>
				<TableResponsive
					listItems={listReferrals}
					tableColumns={tableColumns}
					tableOptions={tableOptions}
					tableDefaultSorted={tableDefaultSorted}
					overlay={overlay}
					barFilters={(
						<Row className="mb-2">
							<Col md="3">
								<div className="me-2 mb-2 d-inline-block select2-container w-100">
									<input
										className="form-control"
										placeholder="Name to display"
										value={newName}
										onChange={e => setNewName(e.target.value)}
									/>
								</div>
							</Col>
							<Col md="3">
								<div className="me-2 mb-2 d-inline-block select2-container w-100">
									<AsyncSelect
										cacheOptions
										loadOptions={autocompleteNetwork}
										onChange={network => setNewNetwork(network)}
										placeholder="Select Network"
										classNamePrefix="select2-selection"
									/>
								</div>
							</Col>
							<Col md="3">
								<div className="me-2 mb-2 d-inline-block select2-container w-100">
									<AsyncSelect
										cacheOptions
										loadOptions={(input, callback) => autocompleteFaculty(input, callback, newNetwork)}
										onChange={faculty => setNewFaculty(faculty)}
										placeholder="Select Faculty"
										classNamePrefix="select2-selection"
									/>
								</div>
							</Col>
							<Col md="3">
								<div className="me-2 mb-2 d-inline-block select2-container w-100">
									<Button
										outline
										color="primary"
										onClick={() => {
											dispatch(createNewReferral(newName, newNetwork?.value, newFaculty?.value))
										}}
									>
										Create New
									</Button>
								</div>
							</Col>
						</Row>
					)}
				/>
			</CardBody>
		</Card>
	)
}

export default Referrals
