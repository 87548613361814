import React from 'react'
import PropTypes from 'prop-types'
import { deleteUserGdpr } from '@Store/SyncActions/usersActions'

const DeleteUser = props => {

	const { children, userPid } = props

	return (
		<div
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
				let confirmMessage = prompt('This will delete the user PERMANENTLY. Write "permanently delete" to confirm and continue.', '')

				if (confirmMessage === 'permanently delete') {
					deleteUserGdpr(userPid)
						.then(r => {
							e.target.closest('tr').remove()
							alert('User deleted successfully')
						})
				} else {
					alert('Action aborted')
				}
			}}>
			{children}
		</div>
	)
}

DeleteUser.propTypes = {
	userPid: PropTypes.string.isRequired
}

export default DeleteUser
