import { all, call, put, takeEvery } from 'redux-saga/effects'
import { CREATE_AIRTABLE_FACULTY, GET_AIRTABLE_FACULTIES, GET_AIRTABLE_FACULTIES_MAP, UPDATE_AIRTABLE_MAP } from '@Store/Airtable/actionTypes'
import { getHeaders, handleError } from '@Store/helpers'
import axios from 'axios'
import { getAirtableFacultiesMapSuccess, getAirtableFacultiesSuccess } from '@Store/Airtable/actions'

function * getAirtableFaculties () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/airtable/faculties-available`, getHeaders()))
		yield put(getAirtableFacultiesSuccess(data))
	} catch (err) { handleError(err) }
}

function * getAirtableFacultiesMap () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/airtable/faculties`, getHeaders()))
		yield put(getAirtableFacultiesMapSuccess(data))
	} catch (err) { handleError(err) }
}

function * createAirtableFaculty ({ payload : { facultyName } }) {
	try {
		//const { data } = yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/airtable/faculties-available`, { facultyName }, getHeaders()))
		yield put(() => getAirtableFaculties())
	} catch (err) { handleError(err) }
}

function * updateAirtableMap ({ payload : { facultyPid, airtableName } }) {
	try {
		yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/airtable/faculties`, { faculty: facultyPid, airtable: airtableName }, getHeaders()))
		yield call(() => getAirtableFacultiesMap())
	} catch (err) { handleError(err) }
}

function * AirtableSaga () {
	yield all([
		takeEvery(GET_AIRTABLE_FACULTIES, getAirtableFaculties),
		takeEvery(GET_AIRTABLE_FACULTIES_MAP, getAirtableFacultiesMap),
		takeEvery(CREATE_AIRTABLE_FACULTY, createAirtableFaculty),
		takeEvery(UPDATE_AIRTABLE_MAP, updateAirtableMap)
	])
}

export default AirtableSaga
