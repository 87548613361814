import { GET_LIST_NETWORKS, GET_LIST_NETWORKS_SUCCESS, GET_NETWORK, GET_NETWORK_SUCCESS } from '@Store/Network/actionTypes'

export const getListNetworks = () => ({
	type: GET_LIST_NETWORKS,
})

export const getListNetworksSuccess = (payload) => ({
	type: GET_LIST_NETWORKS_SUCCESS,
	payload,
})

export const getNetwork = (networkId) => ({
	type: GET_NETWORK,
	payload: networkId,
})

export const getNetworkSuccess = (payload) => ({
	type: GET_NETWORK_SUCCESS,
	payload,
})
