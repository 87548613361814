import React from 'react'
import UpdateFaculty from '@Pages/Airtable/UpdateFaculty'

export const tableColumns = [
	{
		dataField: 'fpid',
		text: 'ID',
		alignHeader: 'center',
		sort: true
	},
	{
		dataField: 'nname',
		text: 'University',
		sort: true,
		formatter: (cell, row) => {
			return row.nname ? row.nname : '(generic)'
		}
	},
	{
		dataField: 'fname',
		text: 'Faculty',
		sort: true
	},
	{
		dataField: 'new',
		text: 'Airtable',
		sort: false,
		formatter: (cell, row) => (<UpdateFaculty facultyId={row.fpid} facultyName={row.fname}/>)
	}

]

export const tableOptions = tableLength => {
	return ({
		sizePerPage: 25,
		totalSize: tableLength, // replace later with size(customers),
		custom: true,
	})
}

export const tableDefaultSorted = [{
	dataField: 'id',
	order: 'asc'
}]
