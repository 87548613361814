import MetaTags from 'react-meta-tags'
import React from 'react'
import { Alert, Card, CardBody, Col, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import _ from 'lodash'
import { loginUser } from '@Store/actions'
import profile from '@Assets/images/profile-img.png'
import logo from '@Assets/images/Logo.png'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

class Login extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			error: props.error
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.error, prevProps.error)) this.setState({ error: this.props.error })
	}

	// handleValidSubmit
	handleValidSubmit = (event, values) => {
		this.props.loginUser(values)
	}

	render () {
		const { error } = this.state

		return (
			<React.Fragment>
				<MetaTags>
					<title>Login | Wiraki</title>
				</MetaTags>
				<div className="account-pages my-5 pt-sm-5">
					<Row className="justify-content-center">
						<Col xs={12} md={8} lg={6} xl={5}>
							<Card className="overflow-hidden py-0">
								<div className="bg-primary bg-soft">
									<Row>
										<Col xs={7}>
											<div className="text-primary p-4">
												<h5 className="text-primary">Wiraki</h5>
											</div>
										</Col>
										<Col xs={5} className="align-self-end">
											<img src={profile} alt="" className="img-fluid"/>
										</Col>
									</Row>
								</div>
								<CardBody className="pt-0">
									<a href={process.env.REACT_APP_WIRAKI_WEBSITE}>
										<div className="auth-logo-light">
											<div className="avatar-md profile-user-wid mb-1">
											<span className="avatar-title rounded-circle bg-light">
												<img src={logo} alt="" className="rounded-circle" height="50"/>
											</span>
											</div>
										</div>
									</a>
									<div className="p-2">
										<AvForm className="form-horizontal form-group" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }}>
											{error ? <Alert color="danger">{error}</Alert> : null}
											<div className="mb-3">
												<AvField
													name="apikey"
													label="API Key"
													value=""
													className="form-control"
													type="text"
													required
												/>
											</div>
											<div className="mt-3 d-grid">
												<button className="btn btn-primary btn-block" type="submit">
													Login
												</button>
											</div>
										</AvForm>
									</div>
									<div className="mt-5 text-center text-primary">
										<a href={process.env.REACT_APP_WIRAKI_WEBSITE} target="_blank" rel="noreferrer">
											<p>© {new Date().getFullYear()} Wiraki</p>
										</a>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		error: state.Login.error
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			loginUser
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
