import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Layout Related Components
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import { Container } from 'reactstrap'

class VerticalLayout extends Component {
	constructor (props) {
		super(props)
		this.state = {
			isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
			width: 0,
			height: 0
		}
	}

	componentDidMount () {
		document.body.setAttribute('data-sidebar', 'dark')
	}

	render () {

		return (
			<React.Fragment>
				<div id="layout-wrapper">
					<Header/>
					<Sidebar
						theme={'dark'}
						type={'default'}
						isMobile={this.state.isMobile}
					/>
					<div className="main-content">
						<div className="page-content">
							<Container fluid>
								{this.props.children}
							</Container>
						</div>
					</div>
					<Footer/>
				</div>
			</React.Fragment>
		)
	}
}

VerticalLayout.propTypes = {
	children: PropTypes.any,
}

const mapStateToProps = state => {
	return {
		...state.Layout,
	}
}
export default connect(mapStateToProps, {})(withRouter(VerticalLayout))
