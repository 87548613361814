import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Button, ModalBody, ModalHeader} from 'reactstrap'
import {formatCurrency} from '@Helpers/number_helper'
import {Modal} from 'react-bootstrap'
import {getHiringInfo, setHireUser} from '@Store/SyncActions/usersActions'

const SetUserAsHired = props => {

    const {userPid, children, fullName} = props

    const [showModalHiring, setShowModalHiring] = useState(false)
    const [titleModalHiring, setTitleModalHiring] = useState('')
    const [infoModalHiring, setInfoModalHiring] = useState('')
    const [userIdHiring, setUserIdHiring] = useState(null)

    return (
        <>
            <div
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    getHiringInfo(userPid)
                        .then(r => {

                            setUserIdHiring(userPid)
                            if (r.status === 200 && r.data) {
                                setTitleModalHiring(`Confirm that '${fullName}' has been hired`)
                                r.data.fullName = fullName
                                setInfoModalHiring(r.data)
                                setShowModalHiring(true)
                            } else {
                                setUserIdHiring(null)
                            }
                        })
                }}>
                {children}
            </div>
            <Modal
                className="modal-primary"
                show={showModalHiring}
                onHide={() => setShowModalHiring(false)}
            >
                <ModalHeader className="justify-content-center">
                    <strong>{titleModalHiring}</strong>
                </ModalHeader>
                <ModalBody className="text-center">
                    {infoModalHiring && infoModalHiring.users && infoModalHiring.users.length > 0 ?
                        <p>First {infoModalHiring.users.length} voter/s to reward:</p> :
                        <p>No users to be rewarded!</p>}
                    {infoModalHiring && infoModalHiring.users && infoModalHiring.users.map((el, k) => (
                        <div key={k}>
                            {el.fullName} for <strong>{formatCurrency(el.totalReward, 'USD')}</strong>
                        </div>
                    ))}
                    <br/>
                    <div>
                        Total number voters for {infoModalHiring.fullName}: {infoModalHiring.receivedVotes}<br/>
                        Total number of colleagues who will be notified: {infoModalHiring.totalColleagues}</div>
                </ModalBody>
                <div className="modal-footer">
                    <Button
                        className="btn-simple btn-danger btn-fill"
                        type="button"
                        variant="link"
                        onClick={() => {
                            setShowModalHiring(false)
                            setUserIdHiring(null)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-simple btn-primary btn-fill"
                        type="button"
                        variant="link"
                        onClick={() => {
                            let confirmMessage = prompt('Type \'confirm hiring\' to proceed', '')

                            if (confirmMessage === 'confirm hiring') {
                                let companyFee = prompt('Insert the company fee (e.g.: 4500)')
                                if (isNaN(parseFloat(companyFee))) {
                                    alert('Invalid value inserted, it is not a number!')
                                    setShowModalHiring(false)
                                    setUserIdHiring(null)
                                    return
                                }
                                let companyName = prompt('Type the company name')
                                if (companyName.trim() === '') {
                                    alert('Company name cannot be null')
                                    return
                                }
                                companyName = companyName.trim().toLowerCase()
                                setHireUser(userIdHiring, parseFloat(companyFee), companyName)
                                    .then(r => {
                                        if (r.status === 201) {
                                            alert('Done')
                                        } else {
                                            alert('User already hired!')
                                        }

                                    })
                            } else {
                                alert('Action aborted')
                                setShowModalHiring(false)
                                setUserIdHiring(null)
                            }
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </Modal>
        </>
    )
}

SetUserAsHired.propTypes = {
    userPid: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
}

export default SetUserAsHired
