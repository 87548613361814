import { all, fork } from 'redux-saga/effects'

//public
import AccountSaga from '@Store/auth/register/saga'
import AuthSaga from '@Store/auth/login/saga'
import forgetPasswordSaga from '@Store/auth/forgetPassword/saga'
import resetPasswordSaga from '@Store/auth/resetPassword/saga'

import SystemSaga from '@Store/system/saga'

import NetworkSaga from '@Store/Network/saga'
import UsersSaga from '@Store/Users/saga'
import ReferralsSaga from '@Store/Referrals/saga'
import AirtableSaga from '@Store/Airtable/saga'


export default function * rootSaga () {
	yield all([
		//public
		fork(AccountSaga),
		fork(AuthSaga),
		fork(forgetPasswordSaga),
		fork(resetPasswordSaga),

		fork(SystemSaga),
		fork(NetworkSaga),
		fork(UsersSaga),
		fork(ReferralsSaga),
		fork(AirtableSaga),
	])}
