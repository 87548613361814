import PropTypes from 'prop-types'
import React, { Component } from 'react'
import 'react-drawer/lib/react-drawer.css'

import { connect } from 'react-redux'
// Reactstrap

//i18n

class Header extends Component {
	constructor (props) {
		super(props)
		this.state = {
			isSearch: false,
			open: false,
			position: 'right',
		}
	}
	render () {
		return (
			<React.Fragment>
				<header id="page-topbar">
					<div className="navbar-header">

						<div className="d-flex">
							<div className="navbar-brand-box d-lg-none d-md-block">

							</div>
						</div>
					</div>
				</header>
			</React.Fragment>
		)
	}
}

const mapStatetoProps = state => {
	return {
		...state.Layout
	}
}

export default connect(mapStatetoProps, {})(Header)

Header.propTypes = {
	t: PropTypes.any,
}
