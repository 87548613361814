import React from 'react'
import { formatCurrency } from '@Helpers/number_helper'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Buttons from '@Pages/Users/atoms/Buttons'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import EditVotes from '@Pages/Users/atoms/EditVotes'

export const tableColumns = [
	{
		dataField: 'id',
		text: 'Actions',
		headerAlign: 'center',
		sort: false,
		formatter: (cell, row) => {
			return (
				<Buttons row={row}/>
			)
		}
	},
	{
		dataField: 'forms[0].votes',
		text: 'Votes',
		headerAlign: 'center',
		sort: false,
		formatter: (cell, row) => {
			return (
				<EditVotes votes={row.forms[0].votes}/>
			)
		}
	},
	{
		dataField: 'email',
		text: 'Email',
		sort: false,
		headerAlign: 'center',
		align: 'center',
		formatter: (cell, row) => (
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip id="tooltip-disabled">{row.email}<br/><br/>Click to copy</Tooltip>}
			>
				<CopyToClipboard text={row.email}>
					<EmailRoundedIcon style={{ width: '18px' }}/>
				</CopyToClipboard>
			</OverlayTrigger>
		)
	},
	{
		dataField: 'phoneNumber',
		text: 'Phone',
		sort: false,
		headerAlign: 'center',
		align: 'center',
		formatter: (cell, row) => {
			if(!row.phoneNumber) return null
			return (
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip id="tooltip-disabled">{row.phoneNumber}<br/><br/>Click to copy</Tooltip>}
				>
					<CopyToClipboard text={row.phoneNumber}>
						<LocalPhoneRoundedIcon style={{ width: '18px' }}/>
					</CopyToClipboard>
				</OverlayTrigger>
			)
		}
	},
	{
		dataField: 'fullName',
		text: 'Name',
		sort: true
	},
	{
		dataField: 'verified',
		text: 'Verified',
		sort: true,
		headerAlign: 'center',
		align: 'center',
		formatter: (cell, row) => {
			return row.verified ?
				<CheckCircleOutlineIcon color="success" style={{ width: '18px' }}/>
				:
				<NotInterestedIcon color="error" style={{ width: '18px' }}/>
		}
	},
	{
		dataField: 'forms[0].network.name',
		text: 'University',
		headerAlign: 'center',
		sort: true
	},
	{
		dataField: 'forms[0].faculty.name',
		text: 'Faculty',
		headerAlign: 'center',
		sort: true,
		formatter: (cell, row) => {
			return `[${row.forms[0].gid}] ${row.forms[0]?.faculty?.name}`
		}
	},
	{
		dataField: 'forms[0].attendingYear',
		text: 'Year',
		headerAlign: 'center',
		align: 'center',
		sort: true,
		formatter: (cell, row) => {
			return `${row.forms[0].attendingYear} ${row.forms[0].degreeType}`
		}
	},
	{
		dataField: 'referredBy',
		text: 'Referrer',
		headerAlign: 'center',
		align: 'center',
		sort: true
	},
	{
		dataField: 'forms[0].rank.value',
		text: 'Rank',
		headerAlign: 'center',
		sort: true
	},
	{
		dataField: 'forms[0].rank.position',
		text: 'Pos/Ver - Unver/Uniq',
		headerAlign: 'center',
		align: 'center',
		sort: false,
		formatter: (cell, row) => {
			const pos = row.forms[0].rank?.position ? row.forms[0].rank.position : '-'
			return `${pos}/${row.forms[0].info.verified} - ${row.forms[0].info.unverified}/${row.forms[0].info.uniqueNames}`
		}
	},
	{
		dataField: 'referredUsers.countVerified',
		text: 'Invites',
		headerAlign: 'center',
		align: 'center',
		sort: false,
		formatter: (cell, row) => (
			<OverlayTrigger
				key={'top' + row.id}
				placement="left"
				overlay={
					<Tooltip>
						{row.referredUsers && row.referredUsers.items && row.referredUsers.items.map((x, k) => (<div key={k}>{x.name}</div>))}
					</Tooltip>
				}
			>
				<div>{row.referredUsers.countVerified}/{row.referredUsers.countVerified + row.referredUsers.countUnverified}</div>
			</OverlayTrigger>
		)
	},
	{
		dataField: 'rewards.votes',
		text: 'Unlocked',
		headerAlign: 'center',
		sort: true,
		align: 'right',
		formatter: (cell, row) => {
			return formatCurrency(row.rewards.votes, 'USD')
		}

	}
]

export const tableOptions = tableLength => {
	return ({
		sizePerPage: 50,
		totalSize: tableLength, // replace later with size(customers),
		custom: true,
	})
}

export const tableDefaultSorted = [{
	dataField: 'createdAt',
	order: 'desc'
}]
