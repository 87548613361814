import {
	CREATE_AIRTABLE_FACULTY,
	CREATE_AIRTABLE_FACULTY_SUCCESS, GET_AIRTABLE_FACULTIES,
	GET_AIRTABLE_FACULTIES_MAP,
	GET_AIRTABLE_FACULTIES_MAP_SUCCESS, GET_AIRTABLE_FACULTIES_SUCCESS,
	UPDATE_AIRTABLE_MAP,
	UPDATE_AIRTABLE_MAP_SUCCESS
} from '@Store/Airtable/actionTypes'

export const getAirtableFacultiesMap = () => ({
	type: GET_AIRTABLE_FACULTIES_MAP,
	payload: null
})

export const getAirtableFacultiesMapSuccess = (data) => ({
	type: GET_AIRTABLE_FACULTIES_MAP_SUCCESS,
	payload: data
})

export const updateAirtableMap = (facultyPid, airtableName) => ({
	type: UPDATE_AIRTABLE_MAP,
	payload: {facultyPid, airtableName}
})

export const createAirtableFaculty = (facultyName) => ({
	type: CREATE_AIRTABLE_FACULTY,
	payload: {facultyName}
})

export const getAirtableFaculties = () => ({
	type: GET_AIRTABLE_FACULTIES,
	payload: null
})

export const getAirtableFacultiesSuccess = (data) => ({
	type: GET_AIRTABLE_FACULTIES_SUCCESS,
	payload: data
})
