import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { tableColumns, tableOptions, tableDefaultSorted } from './userTableConfig'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import { getHeaders } from '@Store/helpers'
import { userListLoad, userListSave } from '@Store/Users/actions'
import { autocompleteNetwork } from '@Store/SyncActions/networkActions'
import { autocompleteFaculty } from '@Store/SyncActions/facultyActions'
import TableResponsive from '@Molecules/TableResponsive'

const Users = props => {

	const dispatch = useDispatch()

	const [network, setNetwork] = useState()
	const [faculty, setFaculty] = useState()
	const [overlay, setOverlay] = useState(false)

	const listUsersData = useSelector(state => state.Users.list)
	const [listUsers, setListUsers] = useState(listUsersData)

	const loadOptionsUsers = (inputValue, callback) => {
		axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/users`, {
			...getHeaders(),
			params: {
				term: inputValue
			}
		}).then(res => {
			callback(res.data.map(user => ({ value: user.id, label: user.fullName, ...user })))
		})
	}

	useEffect(() => {
		setOverlay(true)
		dispatch(userListLoad({ networkId: network?.value, facultyId: faculty?.value }))
	}, [network, faculty])

	useEffect(() => {
		setOverlay(false)
		setListUsers(listUsersData)
	}, [listUsersData])

	return (
		<Card>
			<CardBody>
				<TableResponsive
					listItems={listUsers}
					tableColumns={tableColumns}
					tableOptions={tableOptions}
					tableDefaultSorted={tableDefaultSorted}
					overlay={overlay}
					barFilters={(
						<Row className="mb-2">
							<Col md="3">
								<div className="me-2 mb-2 d-inline-block select2-container w-100">
									<AsyncSelect
										cacheOptions
										loadOptions={autocompleteNetwork}
										onChange={network => setNetwork(network)}
										placeholder="Select Network"
										classNamePrefix="select2-selection"
									/>
								</div>
							</Col>
							<Col md="3">
								<div className="me-2 mb-2 d-inline-block select2-container w-100">
									<AsyncSelect
										cacheOptions
										loadOptions={(input, callback) => autocompleteFaculty(input, callback, network)}
										onChange={faculty => setFaculty(faculty)}
										placeholder="Select Faculty"
										classNamePrefix="select2-selection"
									/>
								</div>
							</Col>
							<Col md="3">
								<div className="me-2 mb-2 d-inline-block select2-container w-100">
									<AsyncSelect
										cacheOptions
										loadOptions={loadOptionsUsers}
										onChange={user => dispatch(userListSave([user]))}
										placeholder="Find user in database"
										classNamePrefix="select2-selection"
									/>
								</div>
							</Col>
						</Row>
					)}
				/>
			</CardBody>
		</Card>
	)
}

export default Users
