import React from 'react'

import routes from '@Routes/routeConfig'

// Layouts
import VerticalLayout from '@Components/Layout/VerticalLayout'
import NonAuthLayout from '@Components/Layout/NonAuthLayout'

// Authentication related pages
import Login from '@Pages/Authentication/Login'
import Logout from '@Pages/Authentication/Logout'

// Onboarding
// Pages
import Dashboard from '@Pages/Dashboard'
import Users from '@Pages/Users'
import Votes from '@Pages/Users/Votes'
import NameCollisions from '@Pages/Users/NameCollisions/index'
import Airtable from '@Pages/Airtable'
import Referrals from '@Pages/Referrals'

const authProtectedRoutes = [
	// this route should be at the end of all other routes
	// eslint-disable-next-line react/display-name
	{ layout: VerticalLayout, exact: true, path: routes.DASHBOARD.route, component: Dashboard },
	{ layout: VerticalLayout, exact: true, path: routes.USERS.route, component: Users },
	{ layout: VerticalLayout, exact: true, path: routes.USERS_VOTES.route, component: Votes },
	{ layout: VerticalLayout, exact: true, path: routes.REFERRALS.route, component: Referrals },
	{ layout: VerticalLayout, exact: true, path: routes.AIRTABLE.route, component: Airtable },
	{ layout: VerticalLayout, exact: true, path: routes.NAME_COLLISIONS.route, component: NameCollisions },
]

const publicRoutes = [
	{ redirectIfAuthorized: false, layout: NonAuthLayout, exact: true, path: routes.LOGOUT.route, component: Logout },
	{ redirectIfAuthorized: true, layout: NonAuthLayout, exact: true, path: routes.LOGIN.route, component: Login },
]

export { publicRoutes, authProtectedRoutes }
