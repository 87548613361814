
import axios from 'axios'
import authenticationHelper from './autentication'
class ApiHandler {

	static concurrentRequests = 1
	static queueRequests = []
	static requestsInProgress = 0

	static goToLoginPage(){
		document.location.href = '/login'
	}

	static async askNewTask(){
		if (this.requestsInProgress >= this.concurrentRequests) {
			return
		}
		if (this.queueRequests.length > 0) {
			let element = this.queueRequests.shift()
			let res = await this.executeApiRequests(element)
			if (res.status === 401) {
				authenticationHelper.removeApiKey()
				this.queueRequests = []
				this.requestsInProgress = 0
				if(!element.ignoreRedirect) {
					this.goToLoginPage()
				}
				return element.reject(res)
			}
			element.resolve(res)
			await this.askNewTask()
		}
	}

	static async executeApiRequests(task){
		try {
			let res = await axios({
				method: task.method,
				url: task.url,
				data: task.data,
				headers: task.headers
			})
			return {
				status: res.status,
				data: res.data
			}
		} catch (err) {
			console.log(err.response)
			return {
				status: err.response.status,
				data: err.response.data
			}
		}
	}

	static async getRequest(url, authorized = true, ignoreRedirect = false){

		if (authorized && !authenticationHelper.isAuthenticated()) {
			this.goToLoginPage()
			return new Promise((resolve, reject) => {
				return reject(401)
			})
		}
		return new Promise((resolve, reject) => {
			this.queueRequests.push({
				method: 'get',
				url: url,
				data: {},
				headers: {
					'Content-type': 'application/json',
					'apikey': authenticationHelper.getApiKey()
				},
				resolve: resolve,
				reject: reject,
				ignoreRedirect: ignoreRedirect
			})
			this.askNewTask()
		})
	}

	static async postRequest(url, body = {}, authorized = true, ignoreRedirect = false, method='post'){
		if (authorized && !authenticationHelper.isAuthenticated()) {
			this.goToLoginPage()
			return new Promise((resolve, reject) => {
				return reject(401)
			})
		}
		return new Promise((resolve, reject) => {
			this.queueRequests.push({
				method: method,
				url: url,
				data: body,
				headers: {
					'Content-type': 'application/json',
					'apikey': authenticationHelper.getApiKey()
				},
				resolve: resolve,
				reject: reject,
				ignoreRedirect: ignoreRedirect
			})
			this.askNewTask()
		})
	}

	static async patchRequest(url, body, authorized = true, ignoreRedirect = false){
		return await this.postRequest(url, body, true, false, 'patch')
	}

}

export default ApiHandler
