import { combineReducers } from 'redux'

// Authentication
import Login from '@Store/auth/login/reducer'
import Account from '@Store/auth/register/reducer'
import ForgetPassword from '@Store/auth/forgetPassword/reducer'
import ResetPassword from '@Store/auth/resetPassword/reducer'

import System from '@Store/system/reducer'

import Network from '@Store/Network/reducer'
import Users from '@Store/Users/reducer'
import Referrals from '@Store/Referrals/reducer'
import Airtable from '@Store/Airtable/reducer'

const rootReducer = combineReducers({
	// public
	Login,
	Account,
	ForgetPassword,
	ResetPassword,

	System,

	Network,
	Users,
	Referrals,
	Airtable,

})

export default rootReducer
