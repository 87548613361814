import { GET_LIST_NAME_COLLISIONS_SUCCESS, TOGGLE_MODAL_EDIT_USER, USER_LIST_SAVE } from '@Store/Users/actionTypes'

const INIT_STATE = {
	list: [],
	modalEdit: {
		open: false,
		data: null
	},
	listCollisions: []
}

const Users = (state = INIT_STATE, action) => {
	switch (action.type) {
		case USER_LIST_SAVE:
			return {
				...state,
				list: action.payload
			}
		case TOGGLE_MODAL_EDIT_USER:
			return {
				...state,
				modalEdit: {
					open: action.payload.open,
					data: action.payload.data
				}
			}
			case GET_LIST_NAME_COLLISIONS_SUCCESS:
			return {
				...state,
				listCollisions: action.payload
			}
		default:
			return state
	}
}

export default Users
