import { FIND_NETWORK_BY_TERM, GET_LIST_NETWORKS_SUCCESS, GET_NETWORK_SUCCESS } from '@Store/Network/actionTypes'

const INIT_STATE = {
	list: [],
	network: {},
}

const Network = (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_LIST_NETWORKS_SUCCESS:
			return {
				...state,
				list: action.payload
			}
		case GET_NETWORK_SUCCESS:
			return {
				...state,
				network: action.payload
			}
		default:
			return state
	}
}

export default Network
