import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Dashboard = props => {

	return (
		<Row>
			<Col xl="4">

			</Col>
		</Row>
	)
}

export default Dashboard
