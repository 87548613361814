import { all, call, put, takeEvery } from 'redux-saga/effects'
import { GET_LIST_NAME_COLLISIONS, SAVE_EDIT_USER, SAVED_EDIT_USER, USER_LIST_LOAD } from '@Store/Users/actionTypes'
import { getHeaders, handleError } from '@Store/helpers'
import axios from 'axios'
import { savedEditUser, saveListNameCollisions, toggleModalEditUser, userListSave } from '@Store/Users/actions'
import queryString from 'query-string'

function * getListUsers ({ payload: { networkId, facultyId, term } }) {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/users`, {
			...getHeaders(),
			params: {
				network: networkId || null,
				faculty: facultyId || null,
				term: term || null
			}
		}))

		data.map(user => {
			if(!user.forms[0]) console.log(user)
		})



		yield put(userListSave(data))
	} catch (err) { handleError(err) }
}

function * updateUser ({ payload: { userId, data } }) {
	try {
		yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/crm/${userId}`, data, getHeaders()))
		yield all([
			put(savedEditUser()),
			put(toggleModalEditUser(false))
		])
	} catch (err) { handleError(err) }
}

function * getListNameCollisions () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/name-collisions?${queryString.stringify({ analyzed: false })}`, getHeaders()))
		yield put(saveListNameCollisions(data))
	} catch (err) { handleError(err) }
}

function * UsersSaga () {
	yield all([
		yield takeEvery(USER_LIST_LOAD, getListUsers),
		yield takeEvery(SAVED_EDIT_USER, getListUsers),
		yield takeEvery(SAVE_EDIT_USER, updateUser),
		yield takeEvery(GET_LIST_NAME_COLLISIONS, getListNameCollisions)
	])
}

export default UsersSaga
