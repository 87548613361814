import React from 'react'
import ApiHandler from '@Store/SyncActions/handler'

export const createApiKey = async (upid) => {
	return await ApiHandler.postRequest(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/users/${upid}/login`)
}
export const deleteUserGdpr = async (upid) => {
	return await ApiHandler.postRequest(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/operations/gdpr`, { user: upid })
}
export const removeEmailNotifications = async (upid) => {
	return await ApiHandler.postRequest(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/operations/remove-notifications-email`, { user: upid })
}
export const getHiringInfo = async (upid) => {
	return await ApiHandler.getRequest(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/hiring/${upid}`)
}
export const setHireUser = async (upid, companyFee, companyName) => {
	return await ApiHandler.postRequest(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/hiring/${upid}`,
		{ companyFee: companyFee, companyName: companyName })
}
export const changeVoteToUser = async (votePid, userPid) => {
	return await ApiHandler.postRequest(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/votes/${votePid}/change`, {user: userPid})
}
export const setAnalyzedName = async (cid) => {
	return await ApiHandler.patchRequest(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/name-collisions/${cid}`, {analyzed: true})
}
export const applyChangeToName = async (cid) => {
	return await ApiHandler.postRequest(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/name-collisions/${cid}/apply`, {analyzed: true})
}
