import { API_ERROR, LOGIN_SUCCESS, LOGIN_USER, LOGOUT_USER, LOGOUT_USER_SUCCESS, } from './actionTypes'

export const loginUser = (token) => {
	return {
		type: LOGIN_USER,
		payload: token,
	}
}

export const loginSuccess = token => ({
	type: LOGIN_SUCCESS,
	payload: token,
})

export const logoutUser = history => {
	return {
		type: LOGOUT_USER,
		payload: { history },
	}
}

export const logoutUserSuccess = () => {
	return {
		type: LOGOUT_USER_SUCCESS,
		payload: {},
	}
}

export const apiError = error => {
	return {
		type: API_ERROR,
		payload: error,
	}
}
