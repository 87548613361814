import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

//i18n
import SidebarContent from './SidebarContent'

class Sidebar extends Component {
	constructor (props) {
		super(props)
		this.state = {}
	}

	render () {
		return (
			<React.Fragment>

				<div className="vertical-menu">
					<div className="navbar-brand-box">

					</div>
					<div data-simplebar className="h-100">
						<SidebarContent/>
					</div>
					<div className="sidebar-background"></div>
				</div>
			</React.Fragment>
		)
	}
}

Sidebar.propTypes = {
	type: PropTypes.string,
}

const mapStateToProps = state => {
	return {
		layout: state.Layout,
	}
}
export default connect(mapStateToProps, {})(withRouter(Sidebar))
