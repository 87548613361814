import React from 'react'
import Buttons from '@Pages/Users/NameCollisions/Buttons'

export const tableColumns = [
	{
		dataField: 'id',
		text: 'Actions',
		headerAlign: 'center',
		sort: false,
		formatter: (cell, row) => {
			return (
				<Buttons row={row}/>
			)
		}
	},
	{
		dataField: 'insertedName',
		text: 'Voted Name',
		sort: true,
	},
	{
		dataField: 'similarName',
		text: 'Found Name',
		sort: true,
	},
	{
		dataField: 'userName',
		text: 'Voter User',
		sort: true,
	},
	{
		dataField: 'networkName',
		text: 'Network',
		sort: true,
	},
	{
		dataField: 'facultyName',
		text: 'Faculty',
		sort: true,
	},
	{
		dataField: 'jaccardScore',
		text: 'Jaccard Score',
		headerAlign: 'center',
		align: 'center',
		sort: true,
	},
	{
		dataField: 'similarityScore',
		text: 'Similarity Score',
		headerAlign: 'center',
		align: 'center',
		sort: true,
	},
]

export const tableOptions = tableLength => {
	return ({
		sizePerPage: 50,
		totalSize: tableLength, // replace later with size(customers),
		custom: true,
	})
}

export const tableDefaultSorted = [{
	dataField: 'id',
	order: 'asc'
}]
