import { GET_AIRTABLE_FACULTIES_MAP_SUCCESS, GET_AIRTABLE_FACULTIES_SUCCESS } from '@Store/Airtable/actionTypes'

const INIT_STATE = {
	listFaculties: [],
	facultiesConfigured: [],
	facultiesNotConfigured: [],
}

const Airtable = (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_AIRTABLE_FACULTIES_SUCCESS:
			return {
				...state,
				listFaculties: action.payload,
			}
		case GET_AIRTABLE_FACULTIES_MAP_SUCCESS:
			return {
				...state,
				facultiesConfigured: action.payload.configured,
				facultiesNotConfigured: action.payload.unconfigured,
			}
		default:
			return state
	}
}

export default Airtable
