import React, { useState } from 'react'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import BlockRoundedIcon from '@mui/icons-material/BlockRounded'
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded'
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded'
import WorkRoundedIcon from '@mui/icons-material/WorkRounded'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import TimelineIcon from '@mui/icons-material/Timeline'
import LinkAccessAsUser from '@Pages/Users/atoms/LinkAccessAsUser'
import DeleteUser from '@Pages/Users/atoms/DeleteUser'
import UnsubscribeUser from '@Pages/Users/atoms/UnsubscribeUser'
import SetUserAsHired from '@Pages/Users/atoms/SetUserAsHired'

const Buttons = props => {

	const { row } = props

	const [dropdownOpen, setDropdownOpen] = useState(false)

	const iconStyle = { width: '18px', marginRight: '4px' }

	return (
		<ButtonDropdown
			size="sm"
			isOpen={dropdownOpen}
			toggle={() => setDropdownOpen(!dropdownOpen)}
		>
			<DropdownToggle caret color="primary" outline>
				Actions <i className="mdi mdi-chevron-down"></i>
			</DropdownToggle>
			<DropdownMenu>
				{/* Pagerank Graph */}
				<DropdownItem className="d-flex align-items-center">
					<a href={`${process.env.REACT_APP_ENDPOINT_API_USER}/public/graphs/ranks?gid=${row.forms[0].gid}&nodes=u:${row.id}`} target="_blank" rel="noreferrer">
						<ScatterPlotIcon color="success" style={iconStyle}/>
						PageRank graph
					</a>
				</DropdownItem>
				{/* Invites Graph */}
				<DropdownItem className="d-flex align-items-center">
					<a href={`${process.env.REACT_APP_ENDPOINT_API_USER}/public/graphs/invites?gid=${row.forms[0].gid}&nodes=u:${row.id}`} target="_blank" rel="noreferrer">
						<TimelineIcon color="warning" style={iconStyle}/>
						Invites graph
					</a>
				</DropdownItem>
				<hr/>
				{/* Access account */}
				<DropdownItem className="d-flex align-items-center">
					<LinkAccessAsUser userPid={row.id}>
						<VpnKeyRoundedIcon color="action" style={iconStyle}/>
						Access account
					</LinkAccessAsUser>
				</DropdownItem>
				{/* Delete account */}
				<DropdownItem className="d-flex align-items-center">
					<DeleteUser userPid={row.id}>
						<PersonRemoveRoundedIcon color="error" style={iconStyle}/>
						Delete account
					</DeleteUser>
				</DropdownItem>
				{/* Unsubscribe */}
				<DropdownItem className="d-flex align-items-center" disabled={row.notificationSettings.unsubscribed}>
					<UnsubscribeUser userPid={row.id}>
						<BlockRoundedIcon color="error" style={iconStyle}/>
						Unsubscribe from notifications
					</UnsubscribeUser>
				</DropdownItem>
				{/* Set user hired */}
				<DropdownItem className="d-flex align-items-center">
					<SetUserAsHired userPid={row.id} fullName={row.fullName} disabled={row.hiring !== null}>
						<WorkRoundedIcon color="info" style={iconStyle}/>
						Set user as hired
					</SetUserAsHired>
				</DropdownItem>
			</DropdownMenu>
		</ButtonDropdown>
	)
}

export default Buttons
