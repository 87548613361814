import toastr from "toastr"
import { NOTIFICATION_GENERIC } from '@Notifications/types'
import "toastr/build/toastr.min.css"

toastr.options = {
	positionClass: "toast-top-right",
	timeOut: 5000,
	extendedTimeOut: 1000,
	closeButton: true,
	debug: false,
	progressBar: false,
	preventDuplicates: false,
	newestOnTop: true,
	showEasing: "swing",
	hideEasing: "linear",
	showMethod: "fadeIn",
	hideMethod: "fadeOut",
	showDuration: 300,
	hideDuration: 1000,
}

export const success = (notification = NOTIFICATION_GENERIC) => {
	toastr.success(notification.SUCCESS.message, notification.SUCCESS.title)
}

export const error = (notification = NOTIFICATION_GENERIC) => {
	toastr.error(notification.ERROR.message, notification.ERROR.title)
}
