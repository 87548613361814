import React from 'react'
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { Col, Row } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import overlayFactory from 'react-bootstrap-table2-overlay'
import PropTypes from 'prop-types'
import LoadingOverlay from 'react-loading-overlay'
LoadingOverlay.propTypes = undefined

const TableResponsive = props => {

	const { listItems, tableColumns, barFilters, tableOptions, tableDefaultSorted, overlay, className } = props

	return (
		<PaginationProvider
			pagination={paginationFactory(tableOptions(listItems.length))}
			keyField="id"
			columns={tableColumns}
			data={listItems}
		>
			{({ paginationProps, paginationTableProps }) => (
				<ToolkitProvider
					keyField="id"
					columns={tableColumns}
					data={listItems}
					search
				>
					{toolkitProps => (
						<React.Fragment>
							<Row className="mb-2">
								<Col md={10}>
									{barFilters}
								</Col>
								<Col md={2}>
									<div className="search-box me-2 mb-2 d-inline-block">
										<div className="position-relative">
											<Search.SearchBar
												placeholder="Search in the table"
												{...toolkitProps.searchProps}
											/>
											<i className="bx bx-search-alt search-icon"/>
										</div>
									</div>
								</Col>
							</Row>

							<Row>
								<Col xl="12">
									<div className="table">
										<BootstrapTable
											keyField={'id'}
											responsive
											bordered={false}
											striped={false}
											condensed
											defaultSorted={tableDefaultSorted}
											classes={'table align-middle table-nowrap'}
											headerWrapperClasses={'thead-light'}
											loading={overlay}
											noDataIndication={(<div className="text-center">No data to display</div>)}
											overlay={overlayFactory({
												spinner: true,
												background: 'rgba(192,192,192,0.1)'
											})}
											wrapperClasses={"table-responsive"}
											{...toolkitProps.baseProps}
											{...paginationTableProps}
										/>
									</div>
								</Col>
							</Row>

							<Row className="align-items-md-center mt-30">
								<Col className="inner-custom-pagination d-flex">
									<div className="d-inline">
										<SizePerPageDropdownStandalone
											{...paginationProps}
										/>
									</div>
									<div className="text-md-right ms-auto">
										<PaginationListStandalone
											{...paginationProps}
										/>
									</div>
								</Col>
							</Row>
						</React.Fragment>
					)
					}
				</ToolkitProvider>
			)
			}</PaginationProvider>
	)
}

TableResponsive.propTypes = {
	listItems: PropTypes.array.isRequired,
	tableColumns: PropTypes.array.isRequired,
	tableOptions: PropTypes.func.isRequired,
	tableDefaultSorted: PropTypes.array.isRequired,
	barFilters: PropTypes.element
}

export default TableResponsive
