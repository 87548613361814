import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap'
import TableResponsive from '@Molecules/TableResponsive'
import { useDispatch, useSelector } from 'react-redux'
import { createAirtableFaculty, getAirtableFaculties, getAirtableFacultiesMap } from '@Store/Airtable/actions'
import { tableColumns, tableDefaultSorted, tableOptions } from '@Pages/Airtable/airtableTableConfig'
import * as notifications from '@Notifications/Notifications'

const Airtable = props => {

	const dispatch = useDispatch()

	const [facultyName, setFacultyName] = useState('')

	const [overlayFacultiesNotConfigured, setOverlayFacultiesNotConfigured] = useState(false)
	const [overlayFacultiesConfigured, setOverlayFacultiesConfigured] = useState(false)

	const facultiesConfiguredData = useSelector(state => state.Airtable.facultiesConfigured)
	const [facultiesConfigured, setFacultiesConfigured] = useState(facultiesConfiguredData)
	useEffect(() => {
		setOverlayFacultiesConfigured(false)
		setFacultiesConfigured(facultiesConfiguredData)
	}, [facultiesConfiguredData])

	const facultiesNotConfiguredData = useSelector(state => state.Airtable.facultiesNotConfigured)
	const [facultiesNotConfigured, setFacultiesNotConfigured] = useState(facultiesNotConfiguredData)
	useEffect(() => {
		setOverlayFacultiesNotConfigured(false)
		setFacultiesNotConfigured(facultiesNotConfiguredData)
	}, [facultiesNotConfiguredData])

	useEffect(() => {
		setOverlayFacultiesNotConfigured(true)
		setOverlayFacultiesConfigured(true)
		dispatch(getAirtableFaculties())
		dispatch(getAirtableFacultiesMap())
	}, [])

	return (
		<>
			<Card>
				<CardBody>
					<div className="input-group">
						<Input
							className="form-control"
							value={facultyName}
							onChange={e => setFacultyName(e.target.value)}
							placeholder="Create new Airtable faculty"
							style={{ maxWidth: '400px' }}
						/>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => {
								if (facultyName && facultyName.length > 0) {
									dispatch(createAirtableFaculty(facultyName))
									setFacultyName('')
									notifications.success()
								}
							}}
						>
							Create
						</button>
					</div>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<TableResponsive
						id="airtable-faculties-not-configured"
						listItems={facultiesNotConfigured}
						tableColumns={tableColumns}
						tableOptions={tableOptions}
						tableDefaultSorted={tableDefaultSorted}
						overlay={overlayFacultiesNotConfigured}
						barFilters={(
							<h5 className="card-category">Faculties not configured</h5>
						)}
					/>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<TableResponsive
						id="airtable-faculties-configured"
						listItems={facultiesConfigured}
						tableColumns={tableColumns}
						tableOptions={tableOptions}
						tableDefaultSorted={tableDefaultSorted}
						overlay={overlayFacultiesConfigured}
						barFilters={(
							<h5 className="card-category">Faculties configured</h5>
						)}
					/>
				</CardBody>
			</Card>
		</>
	)
}

export default Airtable
