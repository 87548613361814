import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationEN from './locales/en/translation.json'
import translationIT from './locales/it/translation.json'
import { getLanguage } from '@Store/helpers'

// the translations
const resources = {
	en: { translation: translationEN, },
	//it: { translation: translationIT, },
}

const language = localStorage.getItem('I18N_LANGUAGE')
if (!language) {
	localStorage.setItem('I18N_LANGUAGE', getLanguage().lang)
}

i18n
	.use(detector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: localStorage.getItem('I18N_LANGUAGE') || 'en',
		fallbackLng: 'en', // use en if detected lng is not available
		keySeparator: '.', // we do not use keys in form messages.welcome
		compatibilityJSON: 'v3',
		transSupportBasicHtmlNodes: true,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	})

export default i18n
