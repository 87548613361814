import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getHeaders, handleError } from '@Store/helpers'
import axios from 'axios'
import { getListReferralsSuccess } from '@Store/Referrals/actions'
import { CREATE_NEW_REFERRAL, GET_LIST_REFERRALS } from '@Store/Referrals/actionTypes'

function * getListReferrals () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/referrals`, getHeaders()))
		yield put(getListReferralsSuccess(data))
	} catch (err) { handleError(err) }
}

function * createNewReferral ({ payload: { nameToDisplay, networkPid, facultyPid } }) {
	try {
		yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/admin/referrals`, { nameToDisplay, network: networkPid, faculty: facultyPid }, getHeaders()))
		yield call(() => getListReferrals())
	} catch (err) { handleError(err) }
}

function * ReferralsSaga () {
	yield all([
		takeEvery(GET_LIST_REFERRALS, getListReferrals),
		takeEvery(CREATE_NEW_REFERRAL, createNewReferral)
	])
}

export default ReferralsSaga
