import React, { useState } from 'react'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import * as actions from '@Store/SyncActions/usersActions'

const EditVotes = props => {

	const { votes } = props

	const [dropdownOpen, setDropdownOpen] = useState(false)

	if (!votes) return null

	return (
		<ButtonDropdown
			size="sm"
			isOpen={dropdownOpen}
			toggle={() => setDropdownOpen(!dropdownOpen)}
		>
			<DropdownToggle caret color="primary" outline>
				<i className="mdi mdi-lead-pencil"></i>
			</DropdownToggle>
			<DropdownMenu>
				{votes.map((vote, index) => (
					<DropdownItem
						key={index} className="d-flex align-items-center" onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						let userPid = prompt('Type the user id to assign to the vote')
						actions.changeVoteToUser(vote.id, userPid)
							.then(r => {
								if (r.status === 201) {
									alert('OK')
								} else {
									alert('Error during the vote assignation')
								}
							})
					}}>
						{vote.name}
					</DropdownItem>
				))}
			</DropdownMenu>
		</ButtonDropdown>
	)
}

export default EditVotes
