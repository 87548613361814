import React from 'react'
import PropTypes from 'prop-types'
import { createApiKey } from '@Store/SyncActions/usersActions'

const LinkAccessAsUser = props => {

	const { children, userPid } = props

	return (
		<div onClick={(e) => {
			e.preventDefault()
			e.stopPropagation()
			createApiKey(userPid)
				.then(r => {
					let token = r.data.access_token
					let url = `${process.env.REACT_APP_USER_AREA_URL}/login-with-token?t=${token}`
					prompt('Browse Incognito!', url)
				})}
		}>
			{children}
		</div>
	)
}

LinkAccessAsUser.propTypes = {
	userPid: PropTypes.string.isRequired
}

export default LinkAccessAsUser
