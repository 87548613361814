import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import TableResponsive from '@Molecules/TableResponsive'
import { useDispatch, useSelector } from 'react-redux'
import { getListNameCollisions } from '@Store/Users/actions'
import { tableColumns, tableDefaultSorted, tableOptions } from '@Pages/Users/NameCollisions/nameCollisionsTableConfig'

const NameCollisions = () => {

	const dispatch = useDispatch()

	const namesData = useSelector(state => state.Users.listCollisions)
	const [names, setNames] = useState(namesData)
	const [overlay, setOverlay] = useState(false)


	useEffect(() => {
		setOverlay(false)
		setNames(namesData)
	}, [namesData])

	useEffect(() => {
		setOverlay(true)
		dispatch(getListNameCollisions())
	}, [])

	return (
		<Card>
			<CardBody>
				<TableResponsive
					listItems={names}
					tableColumns={tableColumns}
					tableOptions={tableOptions}
					tableDefaultSorted={tableDefaultSorted}
					overlay={overlay}
				/>
			</CardBody>
		</Card>
	)
}

export default NameCollisions
