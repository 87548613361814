
const isAuthenticated = () => {
	return JSON.parse(localStorage.getItem('apikey')) || null
}

const getApiKey = () => {
	let apikey = JSON.parse(localStorage.getItem('apikey')) || null
	if(!apikey){
		return null
	}
	return apikey
}

const storeApiKey = (apikey) => {
	localStorage.setItem('apikey', JSON.stringify(apikey))
}

const removeApiKey = () => {
	localStorage.removeItem('apikey')
}

export default {
	isAuthenticated,
	storeApiKey,
	removeApiKey,
	getApiKey
}
