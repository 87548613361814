export const NOTIFICATION_GENERIC = {
	SUCCESS: {
		title: 'Success',
		message: 'Your action was successful',
		type: 'success',
	},
	ERROR: {
		title: 'Error',
		message: 'Your action was not successful',
		type: 'error',
	}
}
