import React from 'react'
import PropTypes from 'prop-types'
import { removeEmailNotifications } from '@Store/SyncActions/usersActions'

const UnsubscribeUser = props => {

	const { userPid, children } = props

	return (
		<div onClick={(e) => {
			e.preventDefault()
			e.stopPropagation()
			let confirmMessage = prompt('This will remove notifications email for the user. Write "remove notifications" to confirm and continue.', '')

			if(confirmMessage === 'remove notifications'){
				removeEmailNotifications(userPid)
					.then(r => {
						alert('User updated')
					})
			}else{
				alert('Action aborted')
			}
		}}>
			{children}
		</div>
	)
}

UnsubscribeUser.propTypes = {
	userPid: PropTypes.string.isRequired
}

export default UnsubscribeUser
