import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import routes from '@Routes/routeConfig'

const Authmiddleware = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	redirectIfAuthorized,
	...rest
}) => (
	<Route
		{...rest}
		render={props => {
			// If protected and there is no key
			if (isAuthProtected && !localStorage.getItem('apikey')) {
				return (
					<Redirect to={{ pathname: routes.LOGIN.location, state: { from: props.location } }}/>
				)
			}
			// If not protected but there is key
			if (redirectIfAuthorized && localStorage.getItem('apikey')) {
				return (
					<Redirect to={{ pathname: routes.DASHBOARD.location, state: { from: props.location } }}/>
				)
			}


			return (
				<Layout>
					<Component {...props} />
				</Layout>
			)
		}}
	/>
)

Authmiddleware.propTypes = {
	isAuthProtected: PropTypes.bool,
	component: PropTypes.any,
	location: PropTypes.object,
	layout: PropTypes.any,
}

export default Authmiddleware
